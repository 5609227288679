
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { useRoute } from "vue-router";
import { ref, computed, watch } from "vue";
import axios from "axios";

export default {
  name: "Folder",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || "Web Radio");
    const matchedFolder = computed(() => route.params.id);
    const content = ref("");
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });

    return { folder, content };
  },
};
